const form = document.querySelector("form");

form.onsubmit = function (e) {
  e.preventDefault();

  const birthDate = e.target.birthDate.valueAsDate;

  calculateTriangleNumbers(birthDate);
  calculateStarNumbers(birthDate);
};

const triangle = document.querySelector("#triangle");
const triangleNums = document.querySelectorAll("#triangle .numbers > span");

const TRI_LEFT = 0;
const TRI_MID_LEFT = 1;
const TRI_TOP = 2;
const TRI_MID_RIGHT = 3;
const TRI_RIGHT = 4;
const TRI_MID_BOTTOM = 5;
const TRI_MID_SEMI_LEFT = 6;
const TRI_MID_SEMI_RIGHT = 7;

const star = document.querySelector("#star");
const starNums = document.querySelectorAll("#star .numbers > span");

const STAR_TOP = 0;
const STAR_PAIR_TOP_RIGHT = 1;
const STAR_TOP_RIGHT = 2;
const STAR_PAIR_BOTTOM_RIGHT = 3;
const STAR_BOTTOM_RIGHT = 4;
const STAR_PAIR_BOTTOM = 5;
const STAR_BOTTOM_LEFT = 6;
const STAR_PAIR_BOTTOM_LEFT = 7;
const STAR_TOP_LEFT = 8;
const STAR_PAIR_TOP_LEFT = 9;
const STAR_CENTER = 10;

function sumDigits(num, once = false) {
  while (num > 9) {
    num = num
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, val) => acc + val);

    if (once) {
      break;
    }
  }

  return num;
}

function calculateTriangleNumbers(birthDate) {
  const day = birthDate.getDate();
  const month = birthDate.getMonth() + 1;
  const year = birthDate.getFullYear();

  let firstWorkNumber = sumDigits(day);
  let secondWorkNumber = month;
  let thirdWorkNumber = sumDigits(year);
  let fourthWorkNumber = sumDigits(firstWorkNumber + secondWorkNumber + thirdWorkNumber);

  let secondPairNumber = sumDigits(secondWorkNumber + thirdWorkNumber);
  let thirdPairNumber = sumDigits(thirdWorkNumber + fourthWorkNumber);

  let triNum1 = secondPairNumber;
  let triNum2 = sumDigits(secondPairNumber + thirdWorkNumber);
  let triNum3 = thirdWorkNumber;
  let triNum4 = sumDigits(thirdPairNumber + thirdWorkNumber);
  let triNum5 = thirdPairNumber;
  let triNum6 = sumDigits(secondPairNumber + thirdPairNumber);
  let triNum7 = sumDigits(triNum2 + triNum4);
  let triNum8 = sumDigits(thirdWorkNumber + triNum6);

  triangleNums[TRI_LEFT].textContent = triNum1;
  triangleNums[TRI_MID_LEFT].textContent = triNum2;
  triangleNums[TRI_TOP].textContent = triNum3;
  triangleNums[TRI_MID_RIGHT].textContent = triNum4;
  triangleNums[TRI_RIGHT].textContent = triNum5;

  triangleNums[TRI_MID_BOTTOM].textContent = triNum6;
  triangleNums[TRI_MID_SEMI_LEFT].textContent = triNum7;
  triangleNums[TRI_MID_SEMI_RIGHT].textContent = triNum8;
}

function calculateStarNumbers(birthDate) {
  const day = birthDate.getDate();
  const month = birthDate.getMonth() + 1;
  const year = birthDate.getFullYear();

  let firstWorkNumber,
    secondWorkNumber,
    thirdWorkNumber,
    fourthWorkNumber,
    fifthWorkNumber,
    sixthWorkNumber;

  let secondPairNumberfirstPairNumber,
    secondPairNubmber,
    thirdPairNumber,
    fourthPairNumber,
    fifthPairNumber;

  firstWorkNumber = sumDigits(day);
  secondWorkNumber = month;
  thirdWorkNumber = sumDigits(year);
  fourthWorkNumber = sumDigits(firstWorkNumber + secondWorkNumber + thirdWorkNumber);
  fifthWorkNumber = sumDigits(
    firstWorkNumber + secondWorkNumber + thirdWorkNumber + fourthWorkNumber
  );
  sixthWorkNumber = sumDigits(
    firstWorkNumber + secondWorkNumber + thirdWorkNumber + fourthWorkNumber + fifthWorkNumber
  );

  firstPairNumber = sumDigits(firstWorkNumber + secondWorkNumber);
  secondPairNumber = sumDigits(secondWorkNumber + thirdWorkNumber);
  thirdPairNumber = sumDigits(thirdWorkNumber + fourthWorkNumber);
  fourthPairNumber = sumDigits(fourthWorkNumber + fifthWorkNumber);
  fifthPairNumber = sumDigits(fifthWorkNumber + firstWorkNumber);

  const starNum22 = sumDigits(secondWorkNumber);

  starNums[STAR_TOP_LEFT].textContent = firstWorkNumber;
  starNums[STAR_TOP].textContent = starNum22;
  starNums[STAR_TOP_RIGHT].textContent = thirdWorkNumber;
  starNums[STAR_BOTTOM_RIGHT].textContent = fourthWorkNumber;
  starNums[STAR_BOTTOM_LEFT].textContent = fifthWorkNumber;
  starNums[STAR_CENTER].textContent = sixthWorkNumber;

  starNums[STAR_PAIR_TOP_LEFT].textContent = firstPairNumber;
  starNums[STAR_PAIR_TOP_RIGHT].textContent = secondPairNumber;
  starNums[STAR_PAIR_BOTTOM_RIGHT].textContent = thirdPairNumber;
  starNums[STAR_PAIR_BOTTOM].textContent = fourthPairNumber;
  starNums[STAR_PAIR_BOTTOM_LEFT].textContent = fifthPairNumber;
}
